import styled from 'styled-components'
import { orange } from 'src/styles/newColors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/app-hero-360/image.webp');
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 800px;

  @media (min-width: ${breakpoints.md}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/app-hero-768/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
  }

  @media (min-width: ${breakpoints.lg})  {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/app-hero-1024/image.webp') ;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 800px;
  }

  @media (min-width: ${breakpoints.xl})  {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/app-hero-1440/image.webp') ;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 809px;
  }

  @media (min-width: ${breakpoints.xxll})  {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/app-hero-1920/image.webp') ;
    min-height: 1024px;
    background-repeat: no-repeat;
    background-size: cover ;

  }

  h1 {
    font-size: 48px;
    line-height: 52px;

    @media (min-width: ${breakpoints.lg})  {
      font-size: 56px;
      line-height: 61px;
    }

    @media (min-width: ${breakpoints.xl})  {
      font-size: 80px;
      line-height: 88px;
    }

    @media (min-width: ${breakpoints.xxl}){
      font-size: 104px;
      line-height: 114px;
    }


  }

  button {
    background: ${orange.extra};
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    border-radius: 8px;
    height: 48px;

    @media ${breakpoints.md} {
      max-width: 328px;
    }
    @media ${breakpoints.lg} {
      max-width: 230px;
    }
    @media ${breakpoints.xl} {
      max-width: 323px;
    }
    @media ${breakpoints.xxxl} {
      max-width: 430px;
    }
  }
`

export const CTA = styled.div`
  background: none;
  border: none;
  padding: 0;
  color: #1C1C1C;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  &:focus {
    border: 2px solid;
  }
  &:hover {
    opacity: 0.7;
  }
`
