import React, { useState } from 'react'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, CTA } from './style'
import { ModalPropsSuperApp } from '../../types'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'

type DataProps = {
  qrBaixeApp?: HTMLImageElement;
  agoraBaixeOAppLink: string;
}

const Hero = ({ setIsModal, setDataLayer }: ModalPropsSuperApp) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Você não sabia que precisava, até ser Inter.',
    element_action: 'click button',
    element_name: 'Abra sua conta',
  })

  return (
    <Section className='py-5 d-flex align-items-start  align-items-md-center' id='o-primeiro-super-app'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-8 px-0'>
            <h1 className='font-citrina text-orange--base  mb-0'>
              Ter uma vida financeira mais inteligente.
            </h1>
            <h3 className='fs-28 lh-3 3fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-grayscale--500 fw-500 mt-3'>
              <span className='fs-28 lh-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 d-block'>Você não sabia que </span>precisava, <strong>até ser Inter.</strong>
            </h3>
            <CTA
              className='fs-16 fs-xl-24 fw-500 mt-4 text-none cursor-pointer text-grayscal--500'
              onClick={() => {
                setIsModal && setIsModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent(dataLayer)
              }}
            >
              Abra sua conta
              <ArrowRight height={24} width={24} color='#161616' className='ml-2' />
            </CTA>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
