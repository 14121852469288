import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import Coin from '@interco/icons/core/finance/ic_coin'
import CreditCard from '@interco/icons/core/finance/ic_credit_card'
import { ModalPropsSuperApp } from '../../types'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

import { Section, Button } from './style'

type DataProps = {
  qrBaixeApp?: HTMLImageElement;
  agoraBaixeOAppLink: string;
}

const Cashback = ({ setIsModal }: ModalPropsSuperApp) => {
  const width = useWidth(300)

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            {
              width === widths.md ? (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/app-768-dobra2-1/image.webp'
                  altDescription=''
                  arrayNumbers={[ 312, 335, 414, 454, 585 ]}
                  arrayNumbersHeight={[ 312, 251, 414, 454, 585 ]}
                />
              ) : (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra2-1-360/image.webp'
                  altDescription=''
                  arrayNumbers={[ 312, 336, 414, 454, 585 ]}
                  arrayNumbersHeight={[ 312, 252, 414, 454, 585 ]}
                />
              )
            }
            <h2 className='fs-40 lh-44 fs-lg-48 lh-lg-52 fs-xl-56 lh-xl-61 fw-700 text-center text-md-left mt-3'>Cashback</h2>
            <h3 className='fs-20 lh-24 fs-md-24 lh-md-28 fs-lg-28 lh-lg-33 fs-xl-40 lh-xl-44 fw-400 text-center text-md-left  mb-0'>Pra você usar como quiser</h3>
            <div className='d-flex align-items-center ml-lg-0  mt-md-3'>
              <div className='d-flex align-items-center justify-content-center '>
                <Coin height={24} width={24} color='#161616' />
                <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-lg-18 lh-lg-21 text-grayscale--500 fw-600 mb-0 ml-2'>Dinheiro na conta</p>
              </div>
              <div className='d-flex align-items-center justify-content-center mt-3 mb-3 mb-md-0 mt-md-0 ml-4'>
                <CreditCard height={24} width={24} color='#161616' />
                <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-lg-18 lh-lg-21 text-grayscale--500 fw-600 mb-0 ml-2 mt-md-0'>Pontos Loop</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-5'>

            {
              width === widths.md ? (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/app-768-dobra2-2/image.webp'
                  altDescription=''
                  arrayNumbers={[ 312, 335, 416, 454, 585 ]}
                  arrayNumbersHeight={[ 312, 251, 416, 454, 585 ]}
                />
              ) : (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra2-2-360/image.webp'
                  altDescription=''
                  arrayNumbers={[ 312, 336, 416, 454, 585 ]}
                  arrayNumbersHeight={[ 312, 252, 416, 454, 585 ]}
                />
              )
            }
            <h2 className='fs-40 lh-44 fs-lg-48 lh-lg-52 fs-xl-56 lh-xl-61 fw-700 text-center mt-3 text-md-left'>Pix no Crédito</h2>
            <h3 className='fs-20 lh-24 fs-md-24 lh-md-28 fs-lg-28 lh-lg-33 fs-xl-40 lh-xl-44 fw-400 text-center text-md-left '>Compre agora e pague depois</h3>
            <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
              <CreditCard height={24} width={24} color='#161616' />
              <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-lg-18 lh-lg-21 text-grayscale--500 fw-600 mb-0 ml-2'>Parcele em até 12x</p>
            </div>
          </div>
          <div className='col-12 d-flex justify-content-center'>
            <Button
              onClick={() => {
                setIsModal && setIsModal(true)
              }}
            >
              Abra sua conta
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Cashback
