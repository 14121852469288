import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { Modal } from 'src/components/Modal'
import ModalAccount from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'

import QrBaixeOapp from './assets/images/qrcode-lp-campanha-branding.svg'

import Hero from './section/hero/_index'
import Cashback from './section/cashback/_index'
import QuemDisseQueAVidaFinanceira from './section/quem-disse-que-vida-financeira/_index'
import DaPraFalarDeDinheiro from './section/da-pra-falar-de-dinheiro/_index'
// import ParaUmavidaFinanceira from './section/para-uma-vida-financeira/_index'
// import VoceNaoSabia from './section/voce-nao-sabia/_index'
// import FacilRapidoGratuito from './section/facil-rapido-gratuito/_index'
// import EscolhaInteligente from './section/escolha-inteligente/_index'

const SuperAppFinanceiro = () => {
  const domReady = useDomReady()
  const [ isModal, setIsModal ] = useState(false)
  const urlMobile = 'https://bancointer.go.link?adj_t=1bnjtsam&adj_adgroup=contadigital&adj_creative=campanhabranding24&adj_fallback=https%3A%2F%2Fgointer.app%2F%3Futm_source%3Dsite%26utm_campaign%3Dsite_contadigital_aquisicao_campanhabranding24&adj_redirect_macos=https%3A%2F%2Fgointer.app%2F%3Futm_source%3Dsite%26utm_campaign%3Dsite_contadigital_aquisicao_campanhabranding24'

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_02',
    section_name: 'Para uma vida financeira mais inteligente.',
    element_action: 'click button',
    element_name: 'Saiba mais',
  })

  const openModal = domReady && (
    <Modal
      isModalOpen={isModal}
      setIsModalOpen={setIsModal}
      locationToRender={document.body}
    >
      <ModalAccount
        closeModal={() => setIsModal(false)}
        dataLayer={dataLayer}
        customOrigin='lp-campanhabranding'
        agoraBaixeOAppLink={urlMobile}
        qrBaixeApp={QrBaixeOapp}
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModal}
      <Hero setDataLayer={setDataLayer} setIsModal={setIsModal} />
      <Cashback setIsModal={setIsModal} setDataLayer={setDataLayer} />
      <QuemDisseQueAVidaFinanceira />
      <DaPraFalarDeDinheiro setDataLayer={setDataLayer} setIsModal={setIsModal} />
      {/* <ParaUmavidaFinanceira setDataLayer={setDataLayer} setIsModal={setIsModal} />
      <VoceNaoSabia setDataLayer={setDataLayer} setIsModal={setIsModal} />
      <FacilRapidoGratuito setDataLayer={setDataLayer} setIsModal={setIsModal} />
      <EscolhaInteligente setDataLayer={setDataLayer} setIsModal={setIsModal} /> */}
    </Layout>
  )
}

export default SuperAppFinanceiro
