import React, { useState } from 'react'

import { Section, Image } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import OpenVideo from 'src/components/OpenVideo'

const QuemDisseQueAVidaFinanceira = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const urlVideo = 'https://www.youtube.com/embed/ZgbSj_2GEMs?si=sa4Ch4WvCcCR3fwv?&autoplay=1'

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_03',
    section_name: 'Dá pra falar de dinheiro com leveza? Sim e podemos provar',
    element_action: 'click video',
    element_name: 'Assista ao vídeo',
    redirect_url: urlVideo,
  })

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-28 lh-33 fs-md-40 lh-md-44 fs-lg-48 lh-lg-52 fs-xl-56 lh-xl-61 text-orange--extra text-center'>
              Quem disse que a vida financeira de um casal fica entre quatro paredes?
            </h2>
            <p className='fs-14 lh-16 fs-md-20 lh-md-24 fs-lg-24 lh-lg-28 fs-xl-28 lh-xl-33 text-grayscale--500 text-center'>
              Um Game Show divertido com Tatá Werneck, Flavia Alessandra e Otaviano Costa, Aline Wirley e Igor Rickli.
            </p>
          </div>
          <div className='col-12'>
            <OpenVideo
              link={urlVideo}
              styles='d-flex justify-content-md-center'
              onClick={() => {
                sendDatalayerEvent(dataLayer)
              }}
            >
              <Image />
            </OpenVideo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default QuemDisseQueAVidaFinanceira
