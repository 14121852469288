import styled from "styled-components"
import { brand, white } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${brand.primary};


  svg {
    width: 28px;
    height: 28px ;
  }

  

`
export const Button = styled.button`
  background: ${white};
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  color: ${brand.primary} ;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;

  &:focus, &:hover {
    outline: none;
    color: ${brand.primary};
  }
`
