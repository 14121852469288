import { breakpoints } from "src/styles/breakpoints"
import styled from "styled-components"

const backgroundMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra3-360/image.webp'
const backgroundMd = 'https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra3-768/image.webp'
const backgroundLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra03-1024/image.webp'
const backgroundXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra3-1440/image.webp'

export const Section = styled.section`
  background-color: #FCF8EE;


  h2 {
    font-family: 'Citrina';
  }

  h3 {
    font-family: 'Citrina';
    color: #1C1C1C;
  }


`

export const Image = styled.image`
  background-image: url(${backgroundMobile}) ;
  background-repeat: no-repeat;
  width: 306px;
  height: 511px;

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${backgroundMd}) ;
    background-repeat: no-repeat;
    width: 696px;
    height: 372px;
  }

  @media (min-width: ${breakpoints.lg}){
    background-image: url(${backgroundLg}) ;
    background-repeat: no-repeat;
    width: 927px;
    height: 495px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${backgroundXl});
    background-repeat: no-repeat;
    width: 1128px;
    height: 602px;
  }

`
