import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'
import { ModalPropsSuperApp } from '../../types'

import { Section, Button } from './style'
import ImageWebp from 'src/components/ImageWebp'
import CreditCard from '@interco/icons/core/finance/ic_credit_card'
import Globe from '@interco/icons/core/home-objects/ic_globe'
import PlaneDeparture from '@interco/icons/core/travel-mobility/ic_plane_departure'
import InterLoop from '@interco/icons/core/brands/ic_inter_loop'
import ShoppingBag from '@interco/icons/core/shopping/ic_shopping_bag'
import Add from '@interco/icons/core/action-navigation/ic_add'
import TrendingUp from '@interco/icons/core/finance/ic_trending_up'
import Coin from '@interco/icons/core/finance/ic_coin'

import tudoParaVoceJSON from '../../assets/data/tudo-para-voce-cuidar.json'

type TudoParaVoceProps = {
  icon: string;
  text: string;
}

const TudoParaVoceCuidarDoSeuDinheiro = ({ setIsModal }: ModalPropsSuperApp) => {
  const width = useWidth(300)

  const icons = {
    credit: <CreditCard height={28} width={28} color='#ffffff' />,
    globe: <Globe height={28} width={28} color='#ffffff' />,
    plane: <PlaneDeparture height={28} width={28} color='#ffffff' />,
    interLoop: <InterLoop height={28} width={28} color='#ffffff' />,
    shoppingBag: <ShoppingBag height={28} width={28} color='#ffffff' />,
    add: <Add height={28} width={28} color='#ffffff' />,
    trendingUp: <TrendingUp height={28} width={28} color='#ffffff' />,
    coin: <Coin height={28} width={28} color='#ffffff' />,

  }

  const handleIcons = (icon: string) => {
    switch (icon) {
      case 'credit':
        return icons.credit
      case 'globe':
        return icons.globe
      case 'plane':
        return icons.plane
      case 'interLoop':
        return icons.interLoop
      case 'shoppingBag':
        return icons.shoppingBag
      case 'add':
        return icons.add
      case 'trendingUp':
        return icons.trendingUp
      case 'coin':
        return icons.coin
      default:
        return icons.credit
    }
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            {
              width < widths.md && (
                <div className='text-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra4-360/image.webp'
                    altDescription=''
                    arrayNumbers={[ 288, 288, 288, 288 ]}
                    arrayNumbersHeight={[ 283, 283, 283, 283 ]}
                  />
                </div>
              )
            }
            {
              width === widths.md && (
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra4-768/image.webp'
                  altDescription=''
                  arrayNumbers={[ 696, 696, 696, 696 ]}
                  arrayNumbersHeight={[ 327, 327, 327, 327 ]}
                />
              )
            }
            <h2 className='fs-28 lh-33 fs-md-40 lh-md-44 fs-lg-48 lh-lg-52 fs-xl-56 lh-xl-61 text-white fw-700 mt-4 mb-4'>
              Tudo para você cuidar do seu dinheiro de um jeito inteligente.
            </h2>
            <div className='row d-flex align-items-md-center justify-content-between'>
              <div className='col-lg-6 d-flex  justify-content-md-between'>
                <div className='row'>
                  {
                  tudoParaVoceJSON.map((item: TudoParaVoceProps) => (

                    <div className='col-12 col-md-4 col-lg-6' key={item.text}>
                      <div className='d-flex align-items-center mb-4'>
                        <div>
                          {handleIcons(item.icon)}
                        </div>
                        <p className='fs- 14 lg-16 fs-md-16 lh-md-19 text-white fw-600 mb-0 ml-3'>{item.text}</p>
                      </div>
                    </div>

                  ))
                }
                  <Button onClick={() => setIsModal && setIsModal(true)}>Abra sua conta</Button>
                </div>
              </div>
              <div className='col-lg-6 col-xl-5'>
                {
                  width >= widths.lg ? (
                    <ImageWebp
                      pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/app-dobra4-1024/image.webp'
                      altDescription=''
                      arrayNumbers={[ 374, 374, 374, 374 ]}
                      arrayNumbersHeight={[ 480, 480, 480, 480 ]}
                    />
                  ) : (
                  width >= widths.xl &&
                    <ImageWebp
                      pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/app-dobra4-1440/image.webp'
                      altDescription=''
                      arrayNumbers={[ 452, 452, 452, 452 ]}
                      arrayNumbersHeight={[ 503, 503, 503, 503 ]}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TudoParaVoceCuidarDoSeuDinheiro
